import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const Title = makeShortcode("Title");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Aside = makeShortcode("Aside");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Aside>`}</inlineCode>{` component is a wrapper component that adds styling to make the
text display smaller than the default body text; using `}<inlineCode parentName="p">{`body-long-01`}</inlineCode>{` and adds
the correct top border styles.`}</p>
      <p>{`It should only be used within a `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` component with specific parameters.
`}<inlineCode parentName="p">{`<Column colMd={2} colLg={3} offsetMd={1} offsetLg={1}>`}</inlineCode></p>
    </PageDescription>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Warning:`}</strong>{` If you use more than one `}<inlineCode parentName="p">{`<Aside>`}</inlineCode>{` component on a single page, you
need to provide an accessibility label so that someone using assistive
technology can quickly understand the purpose of the landmark. See
`}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Complementary_role#Labeling_landmarks"
        }}>{`Mozilla Developer Network’s documentation`}</a>{`
for more information. Props for accessibility labels are described in the
`}<a parentName="p" {...{
          "href": "#props"
        }}>{`Props section`}</a>{` below.`}</p>
    </InlineNotification>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">With hanging rule</Title>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <p>{`It is more important than ever that we own our own ethos, make palpable our
brand values, and incorporate an instantly identifiable IBMness in everything we
do.`}</p>
        <blockquote>
          <p parentName="blockquote">{`Without aesthetic, design is either the humdrum repetition of familiar clichés
or a wild scramble for novelty. Without aesthetic, the computer is but a
mindless speed machine, producing effects without substance, form without
relevant content, or content without meaningful form.`}</p>
          <cite>– Paul Rand</cite>
        </blockquote>
        <p>{`Aesthetic is defined as the philosophical theory or set of principles governing
outward appearance or actions.`}</p>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside aria-label="Example aside" mdxType="Aside">
          <p><strong parentName="p">{`Good design is always good design.`}</strong></p>
          <p>{`What we borrow from our own design history is not a mid-century aesthetic in
stylistic terms, but the modernist attitudes and approach used at the time.`}</p>
        </Aside>
      </Column>
    </Row>
    <Title mdxType="Title">Without hanging rule</Title>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <p>{`Like our brand values, our design ethos must be palpable in everything we
produce. Our philosophy and principles are for designers and non-designers
alike–anyone authoring or authorizing any form of design on behalf of IBM.`}</p>
        <p>{`They provide clear criteria for the conception, creativity and craftsmanship our
brand demands and our clients deserve. The expression of our philosophy may
evolve and expand over time, but our principles are designed to endure, ensuring
everything IBM is distinctly IBM.`}</p>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside aria-label="Example aside" hideRule mdxType="Aside">
          <p>{`A by-product of every experience with IBM should be time—time saved or time
well-spent.`}</p>
        </Aside>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">With hanging rule</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Aside/Aside.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Aside",
        "path": "components/Aside/Aside.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Aside"
      }}>{`<Column colMd={2} colLg={3} offsetMd={1} offsetLg={1}>
  <Aside>

**Good design is always good design.** What we borrow from our own design
history is not a mid-century aesthetic in stylistic terms, but the modernist
attitudes and approach used at the time.

  </Aside>
</Column>
`}</code></pre>
    <Title mdxType="Title">Without hanging rule</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Aside/Aside.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Aside",
        "path": "components/Aside/Aside.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Aside"
      }}>{`<Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} hideRule>
  <Aside>

A by-product of every experience with IBM should be time—time saved or time
well-spent.

  </Aside>
</Column>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`aria-label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify an `}<inlineCode parentName="td">{`aria-label`}</inlineCode>{` value to provide a label to the inner aside element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`aria-labelledBy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify an `}<inlineCode parentName="td">{`aria-labelledby`}</inlineCode>{` value that references the id of an existing element to serve as a label for the inner aside element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hideRule`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hide the hanging rule`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      